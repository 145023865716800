/* eslint-disable */
import React, { Suspense, lazy, useEffect } from "react";
import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import ProtectedRoute from "./config/ProtectedRoute";
import { auth } from "./config/firebase";
import Preloader from "./components/preloader";
import OnlyAdminRoute from "./config/OnlyAdminRoute";
import ProtectedOnboardingRoute from "./config/ProtectedOnboardingRoute";
import OnlyAdminAndBDP from "./config/OnlyAdminAndBDP";

// Lazy load de los componentes para las rutas
const Onboarding = lazy (() => import ("./pages/onboarding-v2"));
const ResetPassword = lazy (() => import ("./pages/reset-password"));
const ThankYou = lazy (() => import ("./pages/thank-you"));
const LiveCalls = lazy(() => import("./pages/apps/live-calls"));
const DashboardFour = lazy(() => import("./pages/dashboard-four"));
const SignIn = lazy(() => import("./pages/signin"));
const SignUp = lazy(() => import("./pages/signup"));
const ReportsPage = lazy(() => import("./pages/reports"));
const Billing = lazy(() => import("./pages/billing"));
const Team = lazy(() => import("./pages/team"));
const Preferences = lazy(() => import("./pages/preferences"));
const Marketplace = lazy(() => import("./pages/marketplace"));
const Integrations = lazy(() => import("./pages/integrations/index"));
const IntegrationsDentalink = lazy(() => import("./pages/integrations/dentalink"));
const IntegrationsWhatsapp = lazy(() => import("./pages/integrations/whatsapp360d"));
const IntegrationsCoverManager = lazy(() => import("./pages/integrations/cover-manager"));
const IntegrationsRestoo = lazy(() => import("./pages/integrations/restoo"));
const IntegrationsTwilio = lazy(() => import("./pages/integrations/twilio"));
const IntegrationsNetelip = lazy(() => import("./pages/integrations/netelip"));
const IntegrationsGolfManager = lazy(() => import("./pages/integrations/golf-manager"));
const IntegrationsGooogleMaps = lazy(() => import("./pages/integrations/google-maps"));
const IntegrationsColzent = lazy(() => import("./pages/integrations/colzent"));
const IntegrationsHubSpot = lazy(() => import("./pages/integrations/hubspot"));
const IntegrationsResdiary = lazy(() => import("./pages/integrations/res-diary"));
const Salud = lazy(() => import("./pages/demos/salud"));
const DrSmile = lazy(() => import("./pages/demos/dr-smile"));
const Aythen = lazy(() => import("./pages/demos/aythen"));
const FernandezVega = lazy(() => import("./pages/demos/fernandez-vega"));
const DemoGenerico = lazy(() => import("./pages/demos/demo-generico"));
const Bots = lazy(() => import("./pages/bots"));
const Locales = lazy(() => import("./pages/locales"));
const LocalesDetails = lazy(() => import("./pages/locales-details"));
const VenueDetails = lazy(() => import("./pages/venue-details"));
const Channels = lazy(() => import("./pages/channels"));
const ChannelsDetailsPage = lazy(() => import("./pages/channels-details"));
const Bot = lazy(() => import("./pages/bot-edit"));
const RestautantBot = lazy(() => import("./pages/restaurant-bot"));
const VerifyAccount = lazy(() => import("./pages/verify-account"));
const ForgotPassword = lazy(() => import("./pages/forgot-password"));
const ErrorNotFound = lazy(() => import("./pages/error-404"));
const Error500 = lazy(() => import("./pages/error-500"));
const Error503 = lazy(() => import("./pages/error-503"));
const Error505 = lazy(() => import("./pages/error-505"));
const Timeline = lazy(() => import("./pages/timeline"));
const HelpCenter = lazy(() => import("./pages/help-center"));
const Calendar = lazy(() => import("./pages/apps/calendar"));
const Calls = lazy(() => import("./pages/apps/calls"));
const Chat = lazy(() => import("./pages/apps/chat"));
const Notifications = lazy(() => import("./pages/notifications/index"));
const NotificationsSchedule = lazy(() => import("./pages/notifications/schedule/index"));
const NewBotEdit = lazy(() => import("./pages/apps/newBotEdit"));

import AntLayout from "./layouts/ant-layout";
import VenusDetailsContainer from "./containers/locales/venue-details";

interface AppContentProps {
  noLayoutRoutes: string[]; 
  loading: boolean; 
}

const App: React.FC = () => {
  const [user, loading] = useAuthState(auth);

  const noLayoutRoutes: any = ["/signin", "/signup", "/onboarding", "/forgot-password", "/reset-password"];
  return (
    <Router>
      <AppContent noLayoutRoutes={noLayoutRoutes} loading={loading} />
    </Router>
  );
};

const AppContent: React.FC<AppContentProps> = ({ noLayoutRoutes, loading }) => {
  const location = useLocation();
  const isNoLayoutRoute = noLayoutRoutes.includes(location.pathname);

  return (
    <>
      {/* Renderiza el Layout y ContentHeader solo si la ruta no está excluida */}
      {!isNoLayoutRoute ? (
        <AntLayout>
          {/* Rutas con Layout y ContentHeader */}

          <Suspense fallback={<Preloader />}>
            <Routes>
              <Route
                path="/"
                element={
                  <OnlyAdminRoute>
                    <ProtectedRoute>
                      <DashboardFour />
                    </ProtectedRoute>
                  </OnlyAdminRoute>
                }
              />
              <Route
                path="/billing"
                element={
                  <ProtectedRoute>
                    <Billing />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/team"
                element={
                  <ProtectedRoute>
                    <Team />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/reports"
                element={
                  <ProtectedRoute>
                    <ReportsPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations"
                element={
                  <ProtectedRoute>
                    <Integrations />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/preferences"
                element={
                  <ProtectedRoute>
                    <Preferences />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/dentalink/:id"
                element={
                  <ProtectedRoute>
                    <IntegrationsDentalink />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/whatsapp_360d/:id"
                element={
                  <ProtectedRoute>
                    <IntegrationsWhatsapp />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/whatsapp_360d"
                element={
                  <ProtectedRoute>
                    <IntegrationsWhatsapp />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/covermanager/:id"
                element={
                  <ProtectedRoute>
                    <IntegrationsCoverManager />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/covermanager"
                element={
                  <ProtectedRoute>
                    <IntegrationsCoverManager />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/restoo/:id"
                element={
                  <ProtectedRoute>
                    <IntegrationsRestoo />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/restoo"
                element={
                  <ProtectedRoute>
                    <IntegrationsRestoo />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/sms_netelip/:id"
                element={
                  <ProtectedRoute>
                    <IntegrationsNetelip />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/sms_netelip"
                element={
                  <ProtectedRoute>
                    <IntegrationsNetelip />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/sms_twilio/:id"
                element={
                  <ProtectedRoute>
                    <IntegrationsTwilio />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/sms_twilio"
                element={
                  <ProtectedRoute>
                    <IntegrationsTwilio />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/golfmanager/:id"
                element={
                  <ProtectedRoute>
                    <IntegrationsGolfManager />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/golfmanager"
                element={
                  <ProtectedRoute>
                    <IntegrationsGolfManager />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/google_maps/:id"
                element={
                  <ProtectedRoute>
                    <IntegrationsGooogleMaps />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/google_maps"
                element={
                  <ProtectedRoute>
                    <IntegrationsGooogleMaps />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/colzent/:id"
                element={
                  <ProtectedRoute>
                    <IntegrationsColzent />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/colzent"
                element={
                  <ProtectedRoute>
                    <IntegrationsColzent />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/hubspot/oauth-callback"
                element={
                  <ProtectedRoute>
                    <IntegrationsHubSpot />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/hubspot/:id"
                element={
                  <ProtectedRoute>
                    <IntegrationsHubSpot />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/hubspot"
                element={
                  <ProtectedRoute>
                    <IntegrationsHubSpot />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/integrations/resdiary"
                element={
                  <ProtectedRoute>
                    <IntegrationsResdiary />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/marketplace"
                element={
                  <ProtectedRoute>
                    <Marketplace />
                  </ProtectedRoute>
                }
              />
              {/* Más rutas */}
              <Route
                path="/bots"
                element={
                  <OnlyAdminAndBDP>
                    <ProtectedRoute>
                      <Bots />
                    </ProtectedRoute>
                  </OnlyAdminAndBDP>
                }
              />
              <Route
                path="/bot/:id"
                element={
                  <OnlyAdminRoute>
                    <ProtectedRoute>
                      <Bot />
                    </ProtectedRoute>
                  </OnlyAdminRoute>
                }
              />
              <Route
                path="/bot"
                element={
                  <OnlyAdminRoute>
                    <ProtectedRoute>
                      <Bot />
                    </ProtectedRoute>
                  </OnlyAdminRoute>
                }
              />
              <Route
                path="/bots"
                element={
                  <OnlyAdminAndBDP>
                    <ProtectedRoute>
                      <Bots />
                    </ProtectedRoute>
                  </OnlyAdminAndBDP>
                }
              />
              <Route
                path="/locales/:id"
                element={
                    <ProtectedRoute>
                      <LocalesDetails />
                    </ProtectedRoute>
                }
              />
              <Route
                path="/locales/venue/:id"
                element={
                    <ProtectedRoute>
                      <VenueDetails />
                    </ProtectedRoute>
                }
              />
              <Route
                path="/locales"
                element={
                    <ProtectedRoute>
                      <Locales />
                    </ProtectedRoute>
                }
              />
              <Route
                path="/channels/:id"
                element={
                  // <OnlyAdminRoute>
                  <ProtectedRoute>
                    <ChannelsDetailsPage />
                  </ProtectedRoute>
                  // </OnlyAdminRoute>
                }
              />
              <Route
                path="/channels"
                element={
                  // <OnlyAdminRoute>
                  <ProtectedRoute>
                    <Channels />
                  </ProtectedRoute>
                  // </OnlyAdminRoute>
                }
              />
             
              <Route
                path="/salud"
                element={
                  <OnlyAdminRoute alsoPartner>
                    <ProtectedRoute>
                      <Salud />
                    </ProtectedRoute>
                  </OnlyAdminRoute>
                }
              />
              <Route
                path="/dr-smile"
                element={
                  <OnlyAdminRoute alsoPartner>
                    <ProtectedRoute>
                      <DrSmile />
                    </ProtectedRoute>
                  </OnlyAdminRoute>
                }
              />
              <Route
                path="/aythen"
                element={
                  <OnlyAdminRoute alsoPartner>
                    <ProtectedRoute>
                      <Aythen />
                    </ProtectedRoute>
                  </OnlyAdminRoute>
                }
              />
              <Route
                path="/fernandez-vega"
                element={
                  <OnlyAdminRoute alsoPartner>
                    <ProtectedRoute>
                      <FernandezVega />
                    </ProtectedRoute>
                  </OnlyAdminRoute>
                }
              />
              <Route
                path="/demo-generico"
                element={
                  <OnlyAdminRoute>
                    <ProtectedRoute>
                      <DemoGenerico />
                    </ProtectedRoute>
                  </OnlyAdminRoute>
                }
              />

            <Route
              path="/onboarding/:type"
              element={
                <ProtectedOnboardingRoute>
                  <Onboarding />
                </ProtectedOnboardingRoute>
              }
            />
            <Route
              path="/onboarding"
              element={
                <ProtectedOnboardingRoute>
                  <Onboarding />
                </ProtectedOnboardingRoute>
              }
            />

            {/* Error Routes */}
            <Route path="/error-500" element={<Error500 />} />
            <Route path="/error-503" element={<Error503 />} />
            <Route path="/error-505" element={<Error505 />} />

            <Route path="/timeline" element={<Timeline />} />

            <Route path="/help-center" element={<HelpCenter />} />

            {/* Apps Routes */}

            {/* <Route
                            path="/apps/calendar"
                            element={<Calendar />}
                        /> */}
            <Route
              path="/conversations/:id"
              element={
                <ProtectedRoute>
                  <Calls />
                </ProtectedRoute>
              }
            />
            <Route
              path="/conversations"
              element={
                <ProtectedRoute>
                  <Calls />
                </ProtectedRoute>
              }
            />
            <Route
              path="/live-calls"
              element={
                <ProtectedRoute>
                  <LiveCalls />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chats/:id"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chats"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route
              path="/notifications/schedule/:id"
              element={
                <OnlyAdminRoute>
                  <ProtectedRoute>
                    <NotificationsSchedule />
                  </ProtectedRoute>
                </OnlyAdminRoute>
              }
            />
            <Route
              path="/notifications/schedule"
              element={
                <OnlyAdminRoute>
                  <ProtectedRoute>
                    <NotificationsSchedule />
                  </ProtectedRoute>
                </OnlyAdminRoute>
              }
            />
            <Route
              path="/notifications"
              element={
                <OnlyAdminRoute>
                  <ProtectedRoute>
                    <Notifications />
                  </ProtectedRoute>
                </OnlyAdminRoute>
              }
            />

            <Route
              path="/newBotEdit/:id"
              element={
                <OnlyAdminRoute>
                  <ProtectedRoute>
                    <NewBotEdit />
                  </ProtectedRoute>
                </OnlyAdminRoute>
              }
            />

            <Route
              path="/newBotEdit"
              element={
                <OnlyAdminRoute>
                  <ProtectedRoute>
                    <NewBotEdit />
                  </ProtectedRoute>
                </OnlyAdminRoute>
              }
            />

            <Route
              path="/restaurant-bot/:id"
              element={
                <OnlyAdminAndBDP>
                  <ProtectedRoute>
                    <RestautantBot />
                  </ProtectedRoute>
                </OnlyAdminAndBDP>
              }
            />

            <Route
              path="/restaurant-bot"
              element={
                <OnlyAdminAndBDP>
                  <ProtectedRoute>
                    <RestautantBot />
                  </ProtectedRoute>
                </OnlyAdminAndBDP>
              }
            />
            <Route path="*" element={<ErrorNotFound />} />
            </Routes>
          </Suspense>

        </AntLayout>
      ) : (
        <Suspense fallback={<Preloader />}>
          <Routes>
            {/* Rutas sin Layout ni ContentHeader */}
            <Route path="/signin" element={loading ? <Preloader /> : <SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/onboarding" element={<Onboarding />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/thank-you" element={<ThankYou />} />
          </Routes>
        </Suspense>
      )}
    </>
  );
};

export default App;
